import { render, staticRenderFns } from "./distributionE.vue?vue&type=template&id=74e68278&scoped=true&"
import script from "./distributionE.vue?vue&type=script&lang=js&"
export * from "./distributionE.vue?vue&type=script&lang=js&"
import style0 from "./distributionE.vue?vue&type=style&index=0&id=74e68278&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74e68278",
  null
  
)

export default component.exports